/* Style for the user profile card */
.prescription-card {
  padding: 10px;
  margin-bottom: unset;
}

/* Style for the user's name */

/* Style for the user's date of birth and sex */
.prescription-card p {
  margin: 0;
}

.prescription-card p:last-child {
  margin-bottom: 0;
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

@media only screen and (max-width: 1440px) {
  .prescription-card p:last-child {
    margin-bottom: 0;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
}

/* Style for links */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.prescription {
  margin-right: 10px;
}
.titan-prescription {
  height: 65px;
  width: 100%;
  border-radius: 11px;
  background-color: #f2f2f2;
  padding: 6px 15px;
  display: flex;
  align-items: start;
}

.card-heading {
  font-size: 16px !important;
  line-height: 1rem;
  padding-left: 10px !important;
}

.medicine-card-heading {
  padding: 0 !important;
  padding-left: 10px !important;
}

.custom-label {
  /* font-size: 12px !important; */
  line-height: 1rem;
}

.card-inner-value {
  font-size: 12px !important;
  line-height: 1rem;
}
p.card-inner-value {
  cursor: none;
}
.p-message-warn-risk {
  font-size: 15px;
}

.p-message-warn-orange {
  font-size: 15px;
}

.warn-button-orange {
  font-size: 10px !important;
}

.warn-button-risk {
  font-size: 10px !important;
}

.p-message-warn-orange {
  margin-top: 15px !important;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
}

.p-message-warn-risk {
  margin-top: 15px !important;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
}

.card-inner-boxes {
  padding: 5px !important;
}

.card-step-number h2 {
  font-size: 20px !important;
}

.card-step-number {
  left: -92px;
  width: 40px !important;
  height: 40px !important;
}

.prescription-card {
  padding: 20px 10px 20px 10px !important;
}

.user-profile-card {
  padding: 20px 10px 20px 10px !important;
}

.medicine-card {
  padding: 0 !important;
}

.pres-card > div {
  display: flex;
  gap: 10px;
}

.pres-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clinical-checks-first {
  display: flex !important;
  align-items: baseline !important;
  margin-top: -45px !important;
  margin-bottom: 0px !important;

  .d-flex {
    align-items: center;
    gap: 10px;

    p {
      font-size: 14px;
    }
  }
}
