.services_kanban {
  width: 100%;
  max-width: 80vw;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: unset !important;
}

.service_kanban {
  width: 100%;
  max-width: 80vw;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: unset !important;
}

.stages-wrapper {
  &::-webkit-scrollbar {
    height: 8px;
  }

  display: flex;
  gap: 50px;
  overflow: scroll;
  height: 100%;
  padding: 25px 20px;
}

.services_kanban {
  .stage {
    flex: 1;
    overflow-y: hidden;
    overflow-x: hidden;

    &.with-scrollbar {
      overflow-y: scroll;

      /* Show scrollbar */
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &:hover::-webkit-scrollbar {
      display: block;
      width: 4px;
      margin-left: 5px;
    }

    &::-webkit-scrollbar-track {
      border: 1px solid #999;
    }

    &:first-child {
      padding-left: 0;
    }
  }

  .service_item {
    margin-bottom: 15px;
    background: #fff;
    padding: 16px;
    width: 100%;
    border-radius: 16px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    display: block;
  }
}

.services_kanban-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service_kanban-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.services_kanban-service {
  border: 1px solid #ddd;
  width: 100%;
  background-color: transparent;
  padding: 2px 6px;
  border-radius: 10px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }

  svg {
    height: 30px;
    width: 40px;
  }
}

.service_kanban-service {
  border: 1px solid #ddd;
  width: 100%;
  background-color: transparent;
  padding: 2px 6px;
  border-radius: 10px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }

  svg {
    height: 30px;
    width: 40px;
  }
}

.services_kanban-column {
  &:not(:first-child) {
    margin-left: 40px;
  }

  height: max-content;
}

span.services_kanban-date {
  background: #eee;
  padding: 2px 6px;
  border-radius: 30px;
  font-size: 12px;
}

.services_kanban .service_item {
  transition: 0.2s linear;

  &:hover {
    background: #f6f6f6;
    cursor: pointer;
    transition: 0.2s linear;
  }
}

.service_kanban .service_item {
  transition: 0.2s linear;

  &:hover {
    background: #f6f6f6;
    cursor: pointer;
    transition: 0.2s linear;
  }
}

.services_iconboxService {
  display: flex;
  align-items: center;
  gap: 8px;

  /* font-weight: 500; */
}

.services_icon > svg {
  width: 15px;
  height: 15px;
}

p.services_kanban-patientName {
  font-size: 16px;
  font-weight: 600;
}

.dueDate_accordion {
  border-radius: 15px !important;
  background: none !important;
  box-shadow: none !important;
}

.service_item .name-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

p.services_kanban-serviceName {
  max-width: 265px;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.services_listview-service {
  justify-content: space-between;
  width: 50%;
}

.stacked-cards-container {
  position: relative;
  z-index: 0;

  > {
    .check_service {
      &:not(:first-child) {
        display: none;
      }

      &:first-child {
        position: relative;
        z-index: 2;
      }
    }

    .bottom-stacked:first-child {
      position: relative;
      z-index: 2;
    }
  }
}

.bottom-stacked {
  height: 25px;
  border-radius: 8px;
  position: relative;
  z-index: 3;
  margin-top: -30px;

  &:last-child {
    margin-bottom: 20px;
    margin-top: -15px;
    z-index: 2;
  }
}

.column-cards-container {
  display: flex;
  flex-direction: column;
  gap: 150px;
}

@media only screen and (max-width: 1180px) {
  .services_kanban {
    max-width: unset;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .stages-wrapper {
    flex-direction: column;
  }
}

.service-create-btn
{
  // background:var(--service-crt-btn-bg);
    display: flex;
    justify-content: end;
    padding-right: 10px;
}

.service-template-sections
{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.temp-section
{
  background:var(--inner-card-bg-color);
  padding: 5px;
  border-radius: 10px;
  padding-top: 10px;
}