.main-div {
  display: block;
  /* padding: 0 20px 0 0; */
  width: 98%;
  margin: 30px auto 100px auto;
}

.clinicalchecks .main-div {
  margin: 5px auto 100px auto;
}

.main-div-inner {
  display: flex;
  justify-content: space-between;
}
.content-div {
  width: 100%;
  padding-left: 60px;
}

button {
  border: none;
  background: transparent;
}

.card-step-number {
  position: relative;
  top: -10px;

  width: 50px;
  height: 50px;
  background: #00ca69;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.card-step-number h2 {
  margin: 0;
}

.image-div {
  width: 60%;
  height: max-content;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}

.img-heading {
  color: var(--h2-color);
  font-size: 15px;
  margin: 0;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.page-content {
  width: 100%;
}

.page-content-darkMode {
  background-color: #111111;
}
.loader {
  border-top: 16px solid blue;
  border-right: 16px solid green;
  border-bottom: 16px solid red;
  border-left: 16px solid pink;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.p-progress-spinner {
  position: relative;
  margin: 0 auto;
  width: 200px;
  height: 200px;
  display: inline-block;
  margin: 200px 100px 100px 800px;
}
.NP-buttons {
  display: flex;
  justify-content: flex-end;
}
.vertical-stepper {
  position: relative;
  border-right: 3px solid #00ca69;
  width: 3%;
  margin-top: 30px !important;
}

.main-div .main-div-heading {
  display: block;
  font-weight: 300;
  margin-bottom: 20px;
}
