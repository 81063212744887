.main-row {
  margin-bottom: 80px;
}

.p-button {
  color: #ffffff;

  border: 1px solid rgb(138, 138, 138);
  height: 40px;
  margin: 0px 8px 0px 8px;
}

.action-footer {
  width: 100%;
}
.actions {
  position: fixed;
  width: inherit;
  bottom: 0;
  display: flex;
  justify-content: flex-end !important;
  padding: 10px;
  background: #efeded;
  margin-top: 20px !important;
  left: 0;
  gap: 8px;
}

.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  /* background: #4F46E5; */
  /* color: #ffffff; */
  /* border-color: #4F46E5; */
}
