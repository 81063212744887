.services_container {
  width: 79%;
  height: 92%;

  overflow: hidden;
}

.services_container-content-area {
  max-height: 88vh;
  height: 86vh;
  margin-top: -10px;
}

.services_container_wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
}

.services_content-bg {
  height: 100%;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
}

.service_content-bg {
  overflow: scroll !important;
  height: 100%;
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
}

.services_container .oval-loading {
  left: 60%;
  top: 45%;
}

@media only screen and (max-width: 1180px) {
  .services_container {
    width: 100%;
  }

  .services_container_wrapper {
    margin: 30px 0;
  }
}

.service-title {
  display: flex;
  font-size: 16px !important;
  font-weight: 600;
  margin-right: 15px;
  color: #222;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;

  .title,
  .title-light {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    margin-right: 15px;
    color: #222;
  }

  .title-dark {
    color: #f2f2f2;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    margin-right: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }

  .services_icon {
    margin-right: 10px;
    margin-left: 10px;
  }
}

p.service_duedate_title {
  font-size: 12px !important;
  font-weight: 400;
  margin: 0;
  width: 100%;
}

.expandIcon_dueDate {
  fill: #a5a5a5 !important;
}

.data-box-container {
  .services_list,
  .MuiButtonBase-root {
    padding: 0 !important;
  }
}

.services_listview .data-box-container .services_list {
  padding: 10px !important;
}

@media screen and (max-width: 600px) {
  .services_kanban .stage {
    padding: unset !important;
    padding-right: 25px !important;
  }
}

/* Large 4K screens and larger */
@media screen and (min-width: 2560px) {
  .automation-menu .MuiPopover-paper {
    top: 175px !important; /* Adjust based on 4K layout */
  }
}

/* Desktop screens (Full HD 1920px) */
@media screen and (min-width: 1440px) and (max-width: 2559px) {
  .automation-menu .MuiPopover-paper {
    top: 175px !important;
  }
}

/* Laptops (1366px) */
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .automation-menu .MuiPopover-paper {
    top: 288px !important;
  }
}

/* Tablets and smaller laptops (768px) */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .automation-menu .MuiPopover-paper {
    top: 346px !important;
  }
}

/* Large mobile devices (600px) */
@media screen and (min-width: 601px) and (max-width: 767px) {
  .automation-menu .MuiPopover-paper {
    top: 210px !important;
  }
}

/* Small mobile devices (max-width: 600px) */
@media screen and (max-width: 600px) {
  .automation-menu .MuiPopover-paper {
    top: 291px !important;
  }
}


/* ---------------------Filter work--------------------------------------------------- */

.filter_button_container {
  width: fit-content;
}

.services_content-bg {
  .filter_button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0;

    span {
      font-size: 14px;
    }
  }

  .modal-content-container {
    margin-bottom: 50px;
    width: 800px;
    height: auto;
    padding: 20px;
    border: 1px solid rgb(183, 183, 183);
    border-radius: 5px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
  }

  .filter_heading {
    display: flex;
    justify-content: space-between;
  }

  .save_filter_button {
    padding: 4px;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid rgb(149, 149, 149);
    cursor: pointer;
    box-shadow: 0px 0px 12px rgba(29, 29, 29, 0.3);
  }

  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover {
    box-shadow: 0px 0px 12px rgba(29, 29, 29, 0.3) !important;
  }

  .filters_fields {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    width: 100%;
    border-radius: 5px;

    input {
      border: 1px solid rgb(149, 149, 149);
      border-radius: 5px;
      padding: 5px;

      &:hover {
        border: 1px solid rgb(129, 129, 129);
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
      }
    }
  }

  .multiple_filters_container {
    display: flex;
    align-items: center;

    span {
      &:hover {
        background-color: #ededed;
      }

      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 12px;
    }
  }

  .css-q8hpuo-MuiFormControl-root {
    margin: 5px !important;
  }
}

.service_content-bg {
  .filter_button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0;

    span {
      font-size: 14px;
    }
  }

  .modal-content-container {
    margin-bottom: 50px;
    width: 800px;
    height: auto;
    padding: 20px;
    border: 1px solid rgb(183, 183, 183);
    border-radius: 5px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
  }

  .filter_heading {
    display: flex;
    justify-content: space-between;
  }

  .save_filter_button {
    padding: 4px;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid rgb(149, 149, 149);
    cursor: pointer;
    box-shadow: 0px 0px 12px rgba(29, 29, 29, 0.3);
  }

  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover {
    box-shadow: 0px 0px 12px rgba(29, 29, 29, 0.3) !important;
  }

  .filters_fields {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    width: 100%;
    border-radius: 5px;

    input {
      border: 1px solid rgb(149, 149, 149);
      border-radius: 5px;
      padding: 5px;

      &:hover {
        border: 1px solid rgb(129, 129, 129);
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
      }
    }
  }

  .multiple_filters_container {
    display: flex;
    align-items: center;

    span {
      &:hover {
        background-color: #ededed;
      }

      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 12px;
    }
  }

  .css-q8hpuo-MuiFormControl-root {
    margin: 5px !important;
  }
}

.sms_sent_action {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  padding-right: 2px;
}

.search_patient {
  border-radius: 6px;
  padding: 5px;
}

.search_nfilter {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  margin-left: 20px;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  .d-flex {
    gap: 10px;
    align-items: center;
  }

  .automation-button {
    position: relative;
    left: -30px;
    padding: 10px !important;
    border-radius: 7px;
    min-width: unset !important;
  }
}
.custom-btns-container
{
  display: flex;
  gap: 10px;
}
.automation-menu {
  .MuiPopover-paper {
    border-radius: 10px;
  }

  .menuItem {
    gap: 10px;
    align-items: start;
  }

  .content {
    max-width: 200px;
    background-color: transparent !important;

    p {
      margin: 0;
    }

    p:nth-child(1) {
      margin: 0;
      margin-top: -2px;
      font-size: 14px;
    }

    p:nth-child(2) {
      font-size: 12px;
      font-weight: 300;
      color: #b4afaf;
      margin-top: 3px;
      text-wrap: initial;
      line-height: 1rem;
    }
  }

  .consent_dropdown {
    .content_paragraph {
      margin-bottom: 10px;
    }
    .p-dropdown{
      width: 100% !important;
    }
    .p-inputtext{
      color: #909090 !important;
    }
  }

  .MuiSwitch-track {
    border: 2px solid #617275;
  }
}

.travel_qr_code {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.stage_options {
  display: flex;
  justify-content: space-between;
}

.more_option_icon {
  cursor: pointer;
}

.analytics-tabs-wrapper {
  display: flex;

  /* width: 100%; */
  gap: 20px;
  flex-direction: column;
  justify-content: flex-end;

  .tabs_box {
    margin-left: 20px;
  }
}

.anaylytics-filters-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}